import { RenderByPermission, SearchListModal, request } from '@/utils';
import type { BaseData, IMainSubStructureModel, NormalProgrammeParams } from '@/utils';
import { DownloadOutlined, LinkOutlined, UpCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Space, Tag, message } from 'antd';
import { action, observable } from 'mobx';
import type React from 'react';
import { API } from '../api';
import ApprovalModel from './checkModal/model';
import ImportModel from './import/impoortModel';
import RelevancyEmployerModel from './relevancyEmployer/model';

const { confirm, error } = Modal;

export default class Model {
  // 控制导入弹窗
  @observable public importModalVisible = false;

  // 导入失败弹窗
  @observable public importFailModalVisible = false;

  // 雇主关联弹窗
  public relevancyEmployerStore = new RelevancyEmployerModel(this);
  public importStore = new ImportModel(this);

  // 审核弹窗
  public approvalStore = new ApprovalModel(this);
  constructor() {
    this.mainStore.grid.onQuery();
  }

  @observable public filterSet: Partial<NormalProgrammeParams> = {
    filterItems: [
      {
        type: 'input',
        field: 'positionName',
        label: '职位名称',
        placeholder: '请输入',
      },
      {
        type: 'input',
        field: 'companyName',
        label: '公司名称',
        placeholder: '请输入',
      },
      {
        type: 'input',
        field: 'createByName',
        label: '操作人',
        placeholder: '请输入',
      },
      {
        type: 'select',
        field: 'bindStatus',
        label: '关联状态',
        data: [
          {
            label: '已关联真实雇主',
            value: '1',
          },
          {
            label: '已关联虚拟雇主',
            value: '2',
          },
          {
            label: '未关联',
            value: '0',
          },
        ],
      },
      {
        type: 'select',
        field: 'positionStatus',
        label: '职位状态',
        data: [
          {
            label: '已上线',
            value: '1',
          },
          {
            label: '待上线',
            value: '2',
          },
          {
            label: '待审核',
            value: '3',
          },
          {
            label: '审核失败',
            value: '4',
          },
        ],
      },
    ],
  };

  public grid: IMainSubStructureModel = {
    buttons: [
      {
        permissionId: '28',
        text: '导入',
        iconNode: <DownloadOutlined />,
        handleClick: () => {
          this.importStore.openImportModal();
        },
      },
      {
        permissionId: '63',
        text: '关联雇主',
        iconNode: <LinkOutlined />,
        handleClick: () => {
          const rows = this.mainStore.grid.gridModel.selectRows;
          const unBindRows = rows.filter((row) => row.bindStatus !== 0);
          if (!rows.length || unBindRows.length) {
            message.warning('请选择职位且只选择【关联状态】为【未绑定】的职位');
            return;
          }
          this.onRelevancyEmployer(Array.from(this.mainStore.grid.gridModel.selectedIds));
        },
      },
      {
        permissionId: '63',
        text: '批量上线',
        iconNode: <UpCircleOutlined />,
        handleClick: () => {
          const rows = this.mainStore.grid.gridModel.selectRows;
          const waitUpRows = rows.filter((row) => row.positionStatus === 2);
          const ids = waitUpRows.map((v) => v.id);
          if (!rows.length || !waitUpRows.length) {
            message.warning('请选择职【职位状态】为【待上线】的职位!');
            return;
          }
          this.batchOnLineOrOffline(ids, 2);
        },
      },
    ],
    grid: {
      columns: [
        {
          key: 'opreations',
          name: '操作',
          frozen: true,
          width: 200,
          formatter: ({ row }) => {
            const pageId = new URLSearchParams(window.location.search)?.get('pageId');
            const { positionStatus, id, bindStatus } = row;
            return (
              <div>
                <RenderByPermission permissionId={`${pageId}_21`}>
                  <a
                    key="detail"
                    className="px-1"
                    onClick={() => {
                      window.top.RingPermission.openTab(
                        `/pbb-pc-management/positionManagement/index?positionId=${id}&isShowDetail=${true}`,
                        '76',
                        '职位管理',
                      );
                    }}
                    type="link"
                  >
                    详情
                  </a>
                </RenderByPermission>
                <RenderByPermission permissionId={`${pageId}_63`}>
                  {bindStatus === 0 && (
                    <a
                      key="relevance"
                      className="px-1"
                      onClick={() => {
                        this.onRelevancyEmployer([id]);
                      }}
                      type="link"
                    >
                      关联雇主
                    </a>
                  )}
                </RenderByPermission>
                <RenderByPermission permissionId={`${pageId}_12`}>
                  {bindStatus !== 0 && (
                    <a
                      key="online"
                      className="px-1"
                      onClick={() => {
                        this.onLineOrOffline(id, positionStatus === 1 ? 1 : 2);
                      }}
                      type="link"
                    >
                      {positionStatus === 1 ? '下线' : positionStatus === 2 ? '上线' : null}
                    </a>
                  )}
                </RenderByPermission>
                <RenderByPermission permissionId={`${pageId}_14`}>
                  {(positionStatus === 3 || positionStatus === 4) && (
                    <a
                      key="check"
                      className="px-1"
                      onClick={() => {
                        this.approvalStore.onOpenApproveModal(id);
                      }}
                      type="link"
                    >
                      人工审核
                    </a>
                  )}
                </RenderByPermission>
              </div>
            );
          },
        },
        {
          key: 'name',
          name: '职位名称',
        },
        {
          key: 'companyName',
          name: '公司名称',
        },
        {
          key: 'createByName',
          name: '操作人',
        },
        {
          key: 'bindStatusName',
          name: '关联状态',
        },
        {
          key: 'positionStatusName',
          name: '职位状态',
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
      showPager: true,
      showGridOrderNo: true,
    },
    pageId: new URLSearchParams(window.location.search)?.get('pageId'),
    hiddenSubTable: true,
    api: {
      onQuery: (params: Record<string, any>) => {
        const { filterParams, ...rest } = params;
        const postParams = {
          ...filterParams,
          ...rest,
        };
        if (postParams.bindStatus) {
        }
        postParams.clue = postParams.bindStatus ? 0 : 1;

        return request<BaseData<any>>({
          url: API.noCooperateList,
          method: 'POST',
          data: { ...postParams },
        });
      },
    },
  };

  public mainStore = new SearchListModal({
    programme: this.filterSet,
    grid: this.grid,
  });

  @action public onRelevancyEmployer = async (ids?: React.Key[]) => {
    confirm({
      content: '是否确认关联雇主账号？',
      onOk: async () => {
        const res = await request<BaseData<{ employerInfoVOList: any[]; positionIdList: string[] } | string>>({
          url: API.relevancyEmployer,
          method: 'POST',
          data: {
            idList: ids,
          },
        });
        const { data } = res;
        // 如果data是字符串，直接提示用户重新选择，如果data是个对象，则弹窗关联
        if (data && typeof data === 'string') {
          error({
            content: data,
          });
          return;
        }
        if (data && typeof data === 'object' && Object.keys(data).length) {
          // 弹窗直接赋值gridModel的rows,并且传过去职位id
          this.relevancyEmployerStore.onOpenModal(data.positionIdList);
          this.relevancyEmployerStore.gridModel.rows = data.employerInfoVOList;
          return;
        }
        message.success('操作成功');
        this.mainStore.grid.gridModel.onQuery();
      },
    });
  };
  @action public onMultiCount = async () => {
    confirm({
      content: `${[1, 2, 3].map((v) => `【${v}公司】`)}名下有多个雇主账号，需选择绑定的雇主信息`,
      onOk: async () => {
        // this.relevancyEmployerStore.onOpenModal();
        const res = await request<BaseData>({
          url: API.confirmRelevancy,
          method: 'POST',
          data: {
            positionIdList: Array.from(this.mainStore.grid.gridModel.selectedIds),
          },
        });
      },
    });
  };

  // 启用禁用表格项
  @action
  private onLineOrOffline = (id, status: 1 | 2) => {
    confirm({
      title: '操作提示',
      content: `是否${status === 2 ? '上线' : '下线'}该职位？`,
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        const result = await request<BaseData<string>>({
          url: API.enabelPostion,
          method: 'POST',
          data: { id, status },
        });
        message.success(result.data || result.msg || '操作成功');
        this.mainStore.grid.onQuery();
      },
    });
  };

  // 批量启用禁用表格项
  @action
  private batchOnLineOrOffline = (positionList, status: 1 | 2) => {
    confirm({
      title: '操作提示',
      content: `是否批量${status === 2 ? '上线' : '下线'}职位？`,
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        const result = await request<BaseData<string>>({
          url: API.batchEnabelPostion,
          method: 'POST',
          data: { positionList, status },
        });
        message.success(result.data || result.msg || '操作成功');
        this.mainStore.grid.onQuery();
      },
    });
  };
}
